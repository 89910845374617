#SignatoriesEntry {
    hr {
        border-color: #707070;
    }

    &>hr {
        width: calc(100% + 80px);
        position: relative;
        right: -40px;
    }
}