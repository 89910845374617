#NavigationBar {
    display: grid;
    grid-template-rows: 200px auto;

    header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;

        hr {
            height: 5px;
            width: 200px;
            border-radius: 3px;
            background-color: #0b4130;
            margin: 0px;
        }
    }

    // Both item & subitem -ul
    ul {
        list-style: none;
        color: white;
        padding-left: 0;

        a {
            color: white;

            li {
                display: flex;
                align-items: center;
                margin-top: 10px;
                cursor: pointer;

                img {
                    margin-left: 10px;
                }
            }
        }
    }

    // item ul
    ul:has(li.item):not(ul.subitem) {
        padding-right: 30px;

        &>a {
            &>li {
                img {
                    height: 33px;
                    padding: 4px 0;
                }

                p {
                    font-size: 18px;
                }

                &.active {
                    p {
                        position: relative;

                        &::after {
                            content: '';
                            width: 100%;
                            height: 5px;
                            background-color: #31D68F;
                            position: absolute;
                            bottom: -6px;
                            right: 0;
                            border-radius: 10px;
                            animation: underlineReveal .3s;
                        }
                    }
                }
            }
        }
    }

    // subitem ul
    ul:has(>a>li.subitem) {
        padding-right: 9px;
        padding-left: 25px;
        overflow: hidden;
        animation: comingout 1s ease-in-out;
        animation-direction: alternate;

        &>a {
            &:first-child {
                &>li {
                    margin-top: 15px;
                }
            }

            &>li {
                transition: background-color 1s;
                padding: 5px 10px;
                margin-top: 5px;
                border-radius: 4px;


                img {
                    width: 18px;
                    height: 18px;
                }

                p {
                    // font-size: ;
                }

                &.active {
                    background-color: #0d6e4f;
                }
            }
        }
    }
}

@keyframes underlineReveal {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

@keyframes comingout {
    from {
        max-height: 0;
    }

    to {
        max-height: 100%;
    }
}