#Header_Main_Layout {
    box-shadow: 7px -1px 3px 4px #00000029;
    background-color: white;
    height: 100%;
    position: sticky;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .close__icon {
        position: relative;

        i {
            font-size: 24px;
            color: #0c7b58;
            cursor: pointer;
        }
    }

    .user-icon {
        display: flex;
        align-items: center;
        margin-left: 10px;

        img {
            height: 20px;
            margin-left: 5px;
        }

        p {
            color: grey;
            margin: 0;
        }
    }

    button#dropdown-basic {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .dropdown-toggle::after {
        color: grey;
    }

    .dropdown-menu.show {
        border: none;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        padding: 10px 10px;
    }

    a.dropdown-item:hover {
        border-radius: 5px;
    }

    a.dropdown-item:focus {
        background-color: #fff;
    }
}