@media only screen and (max-width: 600px) {
    #Layout_Main .main-platform {
        margin: 100px 10px !important;
        margin-bottom: 40px !important;
    }
    .report-sec{
        padding: 50px 21px;
    }
    .align-between{
        flex-direction: column;
    }
    #FormAddPerson .inputs {
        flex-direction: column;
    }
    .col {
        flex: none !important
    }
    div#RequestDetailsEntry {
        padding: 0 !important;
    }
    #ButtonsFooter {
        margin-top: 70px;
        margin-bottom: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    span.attachment-button {
        text-align: center;
        margin-bottom: 20px !important;
    }
    #Layout_Main .main-platform{
        padding: 20px !important;
    }
    #Layout > div{
        margin-right: 0 !important;
    }
    #Layout aside{
        right: -400px !important;
    }
    body.close-menu #Layout aside{
        right: 0px !important;
    }
    body.close-menu .close__icon i{
        margin-right: 250px ;
    }
    #Layout_Main .status-grid .list{
        margin-bottom: 20px;
    }
    #Layout_Main .main-platform:has(#report-page){
        margin: 40px !important;
        padding: 0 !important;
        margin-top: 100px !important;
    }
    #Layout_Main .main-platform:has(#Dashboard){
        margin: 40px !important;
        padding: 0 !important;
        margin-top: 100px !important;
    }
    #PaginationRow {
        display: flex;
        justify-content: space-between;
        padding: 0;
        flex-direction: column;
        margin-bottom: 20px;
    }
    #PaginationRow .Count {
        justify-content: center;
        margin-bottom: 20px;
    }
    #Layout_Main .dashboard-items {
        padding: 27px !important;
    }
    #RequestDetails section.status-details {
        display: flex;
        gap: 24px !important;
        flex-direction: column;
        text-align: right;
    }
    #Layout_Main .page-title {
        position: absolute;
        top: -45px !important;
        right: 0;
        font-size: 22px !important;
        font-weight: 700;
        color: #707070;
    }
    div#AssignRole .form-group{
        margin-bottom: 15px;
    }
    section.mt-4.d-flex.gap-2.justify-content-between {
        flex-direction: column;
    }

    body.close-menu #Header_Main_Layout .close__icon i {
        font-size: 24px;
        color: #fff !important;
        cursor: pointer;
        z-index: 99999;
        position: absolute;
        right: 0;
        background: #0c7b58;
    }
    #Login .login-box{
        width: 90% !important;
    }
    #Login .login-box input, #Login .login-box button {
        width: 280px !important;
    }
    #ButtonsFooter button {
        width: 168px;
        margin-top: 10px;
    }
    #Layout_pdf div.document-platform {
        margin: 113px 10px 96px 10px;
    }
    #Modal-platform #Modal {
        min-height: 200px;
        width: 100% !important;
    }
}


/* IPAD PRO */

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .col-tab-3{
        flex: 0 0 auto !important;
    width: 25% !important;
    }
    #Layout > div{
        margin-right: 0 !important;
    }
    #Layout aside{
        right: -400px !important;
    }
    body.close-menu #Layout aside{
        right: 0px !important;
    }
    body.close-menu .close__icon i{
        margin-right: var(--navBarWidth) !important;
    }
    #Layout_Main .main-platform{
        padding: 50px 0 !important;
        margin: 100px 40px !important;
    }
    div#PendingRequests{
        padding: 40px;
    }
    div#AddSignature {
        padding: 40px;
    }
    div#AssignRole{
        padding: 40px;
    }
    .searchBar {
        padding: 50px 16px;
    }
    #ButtonsFooter{
        margin-top: 20px !important;
    }
    div#ClosedRequests{
        padding: 40px;
    }
    #Layout_Main .status-grid .list{
        margin-bottom: 20px;
    }
    div#PendingRequestDetails {
        padding: 40px;
    }
    div#InProgress {
        padding: 40px;
    }
    div#SignatoriesEntry {
        padding: 40px;
    }
    #FormAddPerson .inputs {
        gap: 20px;
    }
    #Layout_pdf div.document-platform {
        margin: 120px 20px 110px 20px !important;
    }
    #FormRequestDetails{
        margin-bottom: 50px;
    }
}


/* TABLET */

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .col-tab-3{
        flex: 0 0 auto !important;
    width: 25% !important;
    }
    #Layout > div{
        margin-right: 0 !important;
    }
    #Layout aside{
        right: -400px !important;
    }
    body.close-menu #Layout aside{
        right: 0px !important;
    }
    body.close-menu .close__icon i{
        margin-right: var(--navBarWidth) !important;
    }
    #Layout_Main .main-platform{
        padding: 50px 0 !important;
        margin: 100px 40px !important;
    }
    div#PendingRequests{
        padding: 40px;
    }
    div#AddSignature {
        padding: 40px;
    }
    div#AssignRole{
        padding: 40px;
    }
    .searchBar {
        padding: 50px 16px;
    }
    #ButtonsFooter{
        margin-top: 20px !important;
    }
    div#ClosedRequests{
        padding: 40px;
    }
    #Layout_Main .status-grid .list{
        margin-bottom: 20px;
    }
    div#PendingRequestDetails {
        padding: 40px;
    }
    div#InProgress {
        padding: 40px;
    }

    div#SignatoriesEntry {
        padding: 40px;
    }
    #FormAddPerson .inputs {
        gap: 20px;
    }
    #Layout_pdf div.document-platform {
        margin: 120px 20px 110px 20px !important;
    }
    #FormRequestDetails{
        margin-bottom: 50px;
    }
}


/* 13 INCH SCREEN */

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    #FormRequestDetails{
        margin-bottom: 50px;
    }
}

body.close-menu #Layout > div{
    margin-right: 0;
}
body.close-menu  #Layout aside {
    right: -400px;
}
.searchBar {
    margin-bottom: 25px;
    background-color: white;
    border-radius: 15px;
    position: relative;
    box-shadow: 0px 0px 10px 2px #0000000F;
    padding: 50px 16px;
}
.bi-list::before{
    font-size: 32px;
}
.bi-x::before {
    font-size: 50px;
}
div#RequestDetailsEntry {
    padding: 40px;
}