@mixin keys-style {
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 5px;
  font-size: 17px;
}

@mixin values-style {
  color: #3f3e3e;
  // font-weight: 600;
}

#RequestDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;

  section.status-details {
    display: flex;
    gap: 60px;

    span {
      p {
        &:first-of-type {
          @include keys-style;
        }

        &:last-of-type {
          @include values-style;

          &.status {
            color: white;
          }
        }
      }
    }
  }

  section.description {
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
      p {
        &:first-of-type {
          @include keys-style;
        }

        &:last-of-type {
          @include values-style;
        }
      }
    }
  }

  section.file {

    img {
      height: 17px;
      margin-right: 5px;
    }

    p {
      &:first-of-type {
        @include keys-style;
      }

      &:last-of-type {
        color: var(--color1);
        cursor: pointer;
        // font-weight: 600;
      }
    }
  }

  section.support-files {
    p.key {
      @include keys-style;
    }

    div.files {
      display: flex;
      gap: 25px;
      flex-wrap: wrap;

      p.value {
        color: var(--color1);
        cursor: pointer;
      }
    }
  }

  section.signatory-table {
    table {
      width: 100%;
      border-collapse: separate;
      -webkit-border-vertical-spacing: 12px;

      th {
        @include keys-style;
      }

      td {
        text-align: right;
        @include values-style;

        &.key {
          @include keys-style;
        }

        button {
          height: fit-content;
          color: #31D68F;
          color: var(--color1);
          background-color: inherit;
          padding: 2px 13px;
          border: 2px solid var(--color1);
          border-radius: 5px;
        }
      }
    }
  }

  section.comment {
    p {
      &:first-of-type {
        @include keys-style;
      }

      &:last-of-type {
        @include values-style;
      }
    }
  }

  section.notes {
    p:not(.error-message) {
      @include keys-style;
    }

    textarea {
      width: 100%;

      &.error {
        border-color: red;
      }
    }

    p.error-message {
      color: red;
    }
  }

  section.buttons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    button {
      width: 200px;
      letter-spacing: 1.3px;

      &.Reject {
        background-color: #e56767;
        color: white;
      }

      &.Return {
        background-color: #e1b918;
        color: white;
      }

      &.Approve {
        background-color: var(--color1);
        color: white;
      }
    }
  }

  span.status,
  p.status {
    padding: 3px 20px;
    border-radius: 5px;
    text-align: center;
    display: inline-block;
    width: 150px;

    &.pending {
      background-color: #e1b918;
      color: white;
    }

    &.approved {
      background-color: var(--color1);
      color: white;
    }

    &.returned {
      background-color: #60718a;
      color: white;
    }

    &.rejected {
      background-color: rgb(197, 0, 0);
      color: white;
    }

    &.Closed {
      background-color: #0a0f31;
    }

    &.closed {
      background-color: #0a0f31;
      color: white;
    }

  }

  #Modal {
    padding: 30px;

    #FormAddPerson {
      padding: 0;
    }

    div.buttons {
      display: flex;
      justify-content: flex-end;
      gap: 15px;

      button {
        color: white;
        width: 200px;

        &.cancel {
          background-color: #434343;
        }

        &.submit {
          background-color: var(--color1);
        }
      }
    }
  }
}