#PaginationRow {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 10px;
    .export{
        display: flex;
        align-items: center;
        justify-self: center;
        h3{
            margin: 0;
            font-size: 16px;
            margin-left: 4px;
        }
        img{
            height: 28px;
        }
    }

    .Count {
        display: inline-flex;
        align-items: center;
        gap: 10px;

        // input {
        //     padding: 0;
        //     text-align: center;
        //     border: 2px solid #707070;

        //     &::-webkit-inner-spin-button,
        //     &::-webkit-outer-spin-button {
        //         opacity: 1;
        //     }
        // }
        span.number-input {
            display: inline-flex;
            position: relative;

            input {
                height: 25px;
                width: 50px;
                border-radius: 5px;
                padding: 0 8px 0 16px;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            img {
                position: absolute;
                left: 5px;
                padding: 3px 2px;

                &:hover {
                    background-color: rgb(202, 202, 202);
                }

                &:active {
                    background-color: rgb(136, 136, 136);
                }

                &.up {
                    top: 2px;
                    rotate: 180deg;
                }

                &.down {
                    bottom: 2px;
                }
            }
        }

        p {
            font-weight: 600;
        }
    }

    .Search {
        position: relative;
        display: inline-flex;
        align-items: center;

        input {
            height: 35px;
            color: #707070;
            width: 250px;
        }

        img {
            position: absolute;
            left: 10px;
            cursor: pointer;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
        }
    }
}