#PDFviewer {

    background-color: #f5f5f7;

    div.buttons {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }

    div.wrapper {
        height: fit-content;
        position: relative;
        margin: 0 auto;
        // display: flex;
        // justify-content: center;

        div.pdf-preview-div {

            &,
            * {
                height: inherit !important;
                width: inherit !important;
            }

            div.react-pdf__Page__annotations {
                display: none;
            }

            div.react-pdf__message {
                display: none;
            }

            canvas.react-pdf__Page__canvas {
                // width: 100% !important;
                // height: auto !important;
                aspect-ratio: 1 / 1;
            }
        }


        div#boxes {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 10;
            top: 0;

            .sign-box {
                position: absolute;
                left: 1px;
                bottom: 1px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                border: 4px solid #8ee6c0;
                // background-color: white;
                height: 110px;
                width: 220px;
                display: none;
                user-select: none;
                font-size: 12px;
                font-weight: 500;
                padding: 1px;
                cursor: grab;

                &.dragging {
                    cursor: grabbing;
                }

                p {
                    text-align: right;
                    pointer-events: none;
                    margin: 0;
                    &.rank{
                        font-size: .6rem;
                    }
                    &.name{
                        font-size: .67rem;
                    }
                }

                img {
                    width: 65%;
                    max-height: 80%;
                    pointer-events: none;
                    object-fit: contain;
                }

                hr {
                    position: absolute;
                    width: 100%;
                    border-color: black;
                    bottom: 12px;
                }

                span {
                    position: absolute;
                    top: 0;
                    left: 110%;
                    padding: 2px 5px;
                    box-shadow: 0 0 3px 1px gainsboro;
                    background-color: white;
                    display: inline-flex;
                    align-items: center;
                    gap: 2px;
                    cursor: pointer;

                    img {
                        width: 15px;
                        pointer-events: none;
                    }
                }
            }
        }
    }
}