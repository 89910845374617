#Login {
    width: 100%;
    height: 100vh;
    background-image: linear-gradient(#00000080, #00000080), url("../public/assets/images/login-background.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 120% 120vh;
    overflow: hidden;
    position: fixed;

    .login-box {
        background-color: white;
        width: 700px;
        margin: 150px auto 0;
        border-radius: 25px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        img.dubai-police-logo {
            width: 180px;
            position: absolute;
            top: 45px;
            right: 80px;
        }
        .main__logo {
            width: 82%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .main__logo {
            width: 82%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        img.tsd-logo {
            width: 90px;
        }

        p.header {
            font-size: 17px;
            font-weight: 600;
            color: #808080;
        }

        button {
            background-color: var(--color1);
            color: white;
            margin: 30px 0;
            font-size: 14px;
        }

        input,
        button {
            width: 380px;
        }
    }
}

#AddSignature,
#AssignRole {
    display: flex;
    flex-direction: column;
    gap: 25px;

    #Modal {
        padding: 40px 60px !important;
        position: relative;

        .close-icon {
            position: absolute;
        }

        div.sign {
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                text-align: left;
                width: 300px;
            }

            span {
                width: 300px;
                height: 150px;
                border: 3px solid var(--color1);
                border-radius: 15px;
                display: inline-flex;
                justify-content: center;
                position: relative;

                img:not(.delete-icon) {
                    height: 100%;
                    max-width: 100%;
                    border-radius: 15px;
                }

                .delete-icon {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    bottom: 5px;
                    right: 3px;
                    cursor: pointer;
                }
            }
        }

        div.input {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 20px;
            position: relative;

            button.upload {
                width: 300px;
                text-align: right;
                color: #747474;
                padding: 0;

                label {
                    width: 100%;
                    height: 100%;
                    display: inline-flex;
                    align-items: center;
                    padding-right: 15px;
                }

                &:hover {
                    background-color: #74747460 !important;
                    color: white;
                }
            }

            button.save {
                width: 150px;
                background-color: var(--color1);
                color: white;
            }

            p.instruction {
                position: absolute;
                color: #D63131;
                bottom: -27px;
                right: 0;
            }
        }
    }
}

#Modal {
    #CheckBoxModal {
        min-height: 200px;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 40px;
        align-items: center;

        h3 {
            text-align: center;
        }

        div.buttons {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-top: 20px;

            button {
                width: 150px;

                &.cancel {
                    background-color: #E95030;
                    color: white;
                }

                &.confirm {
                    background-color: var(--color1);
                    color: white;
                }
            }
        }
    }
}

#PendingRequestDetails {
    section.title {
        position: relative;

        button.back {
            position: absolute;
            width: 150px;
            left: 0;
            top: -20px;
            background-color: #616161;
            color: white;
        }
    }
}

.basic-select {
    height: 48px;

    // &>span {
    //     display: none !important;
    // }

    .select__control {
        background-color: #F8F8F8;
    }

    .select__value-container {
        padding: 0;
    }

    .select__input-container {
        height: 48px;
        margin: 0;
        padding: 0;
    }

    // .select__indicators {
    //     display: none;
    // }
    .css-tj5bde-Svg {
        color: #0c7b58 !important;
    }


}

.css-huc2dk-control {
    box-shadow: none;
    border: 0.5px solid #e8e8e8 !important;
    outline: none;
    border-radius: 8px;
}

.select__control {
    padding-right: 20px;
    border-radius: 8px;
    background-color: #f8f8f8;
    border: 0.5px solid #e8e8e8;
}

.css-tr4s17-option {
    background-color: #0c7b58;
}

.form-check-input:checked {
    background-color: #0c7b58;
    border-color: #0c7b58;
    border-radius: 0.25em;
    width: 25px;
    height: 25px;
    padding: 0;
}

.form-check-input[type=checkbox] {
    border-radius: 0.25em;
    width: 25px;
    height: 25px;
    padding: 0;
}

.form-check-input[type=checkbox]:focus {
    box-shadow: none;
}

.select__box {
    display: flex;
    align-items: center;
}

label.form-label {
    margin: 0;
    margin-right: 10px;
}


.css-qbdosj-Input {
    margin: 0 !important;
    height: 48px !important;
}

.css-1fdsijx-ValueContainer {
    height: 48px !important;
}

.css-1xc3v61-indicatorContainer {
    border: none;
    color: #0c7b58 !important;
}

.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.css-t3ipsp-control {
    background-color: #f8f8f8;
    border: 0.5px solid #e8e8e8 !important;
    outline: none !important;
    box-shadow: none !important;
}

.css-t3ipsp-control:hover {
    border-color: #0c7b58;
}

.css-894a34-indicatorSeparator {
    display: none !important;
}

h4.title2 {
    font-size: 28px;
    font-weight: 700;
    color: #707070;
    margin-bottom: 15px;
}
.backButtonSection {
    display: flex;
    align-items: flex-end;
    direction: ltr;
    button {
        width: 100px;
        background-color: var(--color1);
        color: white;

        &.back {
            background-color: #434343;
        }

        &.danger {
            background-color: #e56767;
        }

    }
}

.Notfound {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

img.circles-loader {
    width: 100px;
}

.loader-div {
    display: flex;
    justify-content: center;
}

.loader-style {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

.supportedFile {
    position: relative;
    margin-bottom: 5px;
}

.supportedFile button {
    position: absolute;
    z-index: 99;
    right: 1px;
    bottom: 0px;
    background: #fff;
    border-radius: 100%;
    width: 25px !important;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.supportedFile button img {
    height: 22px;
    padding: 0;
}

.supportedFile img {
    border-radius: 7px;
    height: 75px;
    object-fit: cover;
}

.supportedFile button:hover {
    background: #fff;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    opacity: 1.1 !important;
}