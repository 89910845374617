@font-face {
  font-family: "Dubai-regular";
  src: url("../public/assets/fonts/Dubai-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Dubai-regular";
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 2000px;
  direction: rtl;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color1: #0d7b58;
  --navBarWidth: 260px;
  --headerHeight: 90px;
}

.App {
  * {
    font-family: "Dubai-regular";
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: initial;
  }

  pre {
    margin: 0;
    font-size: inherit;
    height: 50px;

    &.pending {
      color: #5a5d5c;
    }

    &.approved {
      color: #308e70;
    }

    &.rejected {
      color: #e95030;
    }
  }

  button {
    border: none;
    border-radius: 8px;
    height: 48px;
    transition: background-color 0.3s;

    &:hover {
      opacity: 0.8 !important;
    }

    &:active {
      opacity: 0.7 !important;
    }
  }
  
  .css-huc2dk-control{
    box-shadow: none;
    border: 0.5px solid #e8e8e8 !important;
    outline: none;
    border-radius: 8px;
  }

  .select__control {
      padding-right: 20px;
      border-radius: 8px;
      background-color: #f8f8f8;
  border: 0.5px solid #e8e8e8;
  }
  .css-tr4s17-option{
    background-color: #0c7b58;
  }
  input,
  textarea {
    border: none;
    padding: 12px 20px 7px;
    background-color: #f8f8f8;
    border: 0.5px solid #e8e8e8;
    direction: rtl;
    font-weight: 500;
    color: #505050;
    font-size: 14px;
    border-radius: 8px;
    height: 48px;

    &::placeholder {
      color: #a0a0a0;
    }

    &:focus {
      outline: none;
      border: 0.5px solid #e8e8e8;
      // outline-offset: 2.5px;
    }

    &.error {
      border-color: red;
      color: red;

      &::placeholder {
        color: red;
      }

      & ~ p.error-message {
        color: red;
      }
    }
  }

  select {
    height: 48px;
    border-color: #e8e8e8;
    border-radius: 8px;
    background-color: #f8f8f8;
    padding: 12px 10px;
    padding-left: 20px;

    &:focus {
      outline-color: #e8e8e8;
    }
  }

  textarea {
    height: auto;
    resize: none;
  }
}

.searchBar {
  .form-group {
    margin-bottom: 20px;
    label {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  margin-bottom: 25px;
  background-color: white;
  border-radius: 15px;
  position: relative;
  box-shadow: 0px 0px 10px 2px #0000000F;
  padding: 50px 40px;
  .pl-0 {
    padding-left: 0 !important;
  }
  .basic-select .select__control {
    background-color: #f8f8f8;
    border-radius: 8px;
    border: 0.5px solid #e8e8e8 !important;
    height: 48px;
    font-size: 14px !important;
  }
  .btn-primary {
    width: 150px;
    background-color: var(--color1);
    color: white;
  }
  .btn-primary:hover{
    width: 150px;
    background-color: var(--color1);
    color: white;
  }
  .search-icon{
    position: absolute;
    left: 10px;
    top: 33%;
    height: 17px;
  }
  .form-control:focus{
    outline:#0d7b58;
    box-shadow: none;
    border: 1px solid #0d7b58;
  }

  
}
.report-sec{
  min-height: 100px;
  background-color: white;
  border-radius: 15px;
  position: relative;
  box-shadow: 0px 0px 10px 2px #0000000F;
  padding: 50px 40px;
}


ul.pagination {
  justify-content: left;
  padding: 0;
  margin: 0;
  .page-item:first-child .page-link{
    border-radius: 0;
    border-left: 0;
  }
  .page-item:last-child .page-link{
    border-radius: 0;
  }
  li.page-item.pageActive a {
    background-color: #308e70;
    color: #fff;
  }
  a.page-link:hover{
    color: #0d7b58;
  }
  a.page-link:focus{
    outline: none;
    box-shadow: none;
  }
}
.align-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
}


button.btn.btn-primary {
  background-color: var(--color1);
  color: white;
  padding: 9px 44px;
  border-radius: 8px;
  height: 44px;
  text-align: center;
}
button.btn.btn-danger {
  color: white;
  padding: 9px 44px;
  border-radius: 8px;
  height: 44px;
  text-align: center;
}
button.btn.btn-secondary.mr-2.btn.btn-primary {
  background: #707070;
}
button.btn.btn-secondary{
  background: #707070;
  color: white;
  padding: 9px 44px;
  border-radius: 8px;
  height: 44px;
  text-align: center;
}
.text-left{
  text-align: left;
}
.ml-2{
  margin-left: 10px;
}