#ButtonsFooter {
    margin-top: 70px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;

    button {
        width: 150px;
        background-color: var(--color1);
        color: white;

        &.cancel {
            background-color: #434343;
        }

        &.danger {
            background-color: #e56767;
        }

    }

    span.attachment-button {
        label.attachment-button {
            border-radius: 8px;
            display: inline-flex;
            align-items: center;
            height: 48px;
            background-color: #A2FFBB;
            color: #1A1A1A;
            padding: 0px 20px;
            font-size: 18px;
            gap: 5px;
            cursor: pointer;
            user-select: none;
            position: relative;

            span.attached-file {
                position: absolute;
                top: -30px;
                display: inline-flex;
                align-items: center;
                gap: 10px;

                img {
                    width: 20px;
                }

                p {
                    width: 200px;
                }
            }

            img {
                width: 20px;
            }
        }


        p.error-message {
            color: red;
        }
    }

    div.support-files {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        label.supports {
            background-color: inherit;
            border: 3px solid var(--color1);
            color: var(--color1);
            flex-grow: 1;
            flex-shrink: 0;
            min-width: 280px;
        }

        p.supported__formats {
            color:#000;
            min-width: 280px;
        }

        span.files-preview {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
            margin: 10px;

            span.file-preview {
                position: relative;
                display: inline-flex;

                span.close-button {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #e56767;
                    color: white;
                    border-radius: 100px;
                    height: 20px;
                    margin-left: 5px;
                    aspect-ratio: 1;
                    // position: absolute;
                    right: -8px;
                    top: -9px;
                    cursor: pointer;
                }

                img {
                    width: 40px;

                }
            }
        }
    }


    span.end-buttons {
        display: inline-flex;
        gap: 20px;
    }
}