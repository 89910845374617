#FormRequestDetails {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    span {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc(50% - 15px);

        label {
            font-size: 16px;
            font-weight: 600;
        }
    }
}