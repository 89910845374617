#Header_Pdf_Layout {
    background-color: white;
    box-shadow: -1px -1px 3px 4px #00000029;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 50px;
    width: 100%;
    z-index: 1;
    // position: absolute;
    // top: 90px;


    span.signatory-selection {
        p {
            font-weight: 600;
        }

        &>div {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
        }

        button {
            height: 40px;
            min-width: 200px;
            text-align: right;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row-reverse;

            &.active {
                border: 3px solid #31D68F;
            }

        }
    }

    span.pagination {
        display: inline-flex;
        align-items: center;
        gap: 10px;

        p {
            width: max-content;
            height: 21px;
            font-weight: 600;
            width: 57px;
            flex-shrink: 0;
            text-align: center;
        }

        img {
            width: 10px;
            height: 10px;
            cursor: pointer;

            &.right {
                rotate: 90deg;
            }

            &.left {
                rotate: -90deg;
            }
        }
    }
}