#PageSubTitle {

    h2.title {
        color: #8B8B8B;
        font-size: 20px;
        font-weight: 600;
    }

    hr {
        border-color: #707070;
    }
}