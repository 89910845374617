#Layout_Main {
    .main-platform {
        min-height: 100px;
        background-color: white;
        margin: 130px 70px 100px 80px;
        border-radius: 15px;
        position: relative;
        box-shadow: 0px 0px 10px 2px #0000000F;
        padding: 50px 40px;
        &:has(#report-page){
            background-color: inherit;
            box-shadow: none;
            margin: 80px 30px;
        }
        &:has(#Dashboard){
            background-color: inherit;
            box-shadow: none;
            margin: 80px 30px;
        }
        
    }
    .dashboard-items{
        min-height: 100px;
        background-color: white;
        margin: 0px;
        border-radius: 15px;
        position: relative;
        box-shadow: 0px 0px 10px 2px #0000000F;
        padding: 50px 40px;
        width: 100%;
    }
    .status-grid{
        min-height: 100px;
        margin: 15px 0px 0px 0px;
        border-radius: 15px;
        position: relative;
        // box-shadow: 0px 0px 10px 2px #0000000F;
        // padding: 26px 40px;
        width: 100%;

        .list {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            text-align: center;
            border-radius: 10px;
            padding: 15px 22px;
            background-color: #fff;


h4 {
    margin: 0;
    font-size: 24px;
    color: #0c7b58;
}
            h2{
                font-size: 18px;
                color: #989595;
                margin-bottom: 5px;
                min-height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .page-title {
        position: absolute;
        top: -60px;
        right: 20px;
        font-size: 28px;
        font-weight: 700;
        color: #707070;
    }
    h2.requests {
        color: #0c7b58;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 40px;
    }
}