#FormAddPerson {
    padding: 30px 0px 10px;

    h3.title {
        color: #1A1A1A;
        font-weight: 600;
        font-size: 20px;
    }

    span.reviewer_or {
        label {
            display: inline-flex;
            align-items: center;
            gap: 10px;
            margin-left: 20px;

            input {
                width: 18px;
                accent-color: var(--color1);
            }
        }
    }

    .inputs {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;


        span {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: calc(25% - 15px);
            position: relative;

            label {
                font-size: 16px;
                font-weight: 600;
            }

            select {
                appearance: none;
            }

            img.dropdown-arrow {
                position: absolute;
                left: 15px;
                bottom: 20px;
                width: 14px;
            }

            &.search-keyword {
                display: inline-flex;
                flex-direction: row;
                gap: 15px;
                flex-basis: 75%;
                flex-grow: 0;

                input {
                    width: 66%;
                }

                button {
                    width: 33%;
                    background-color: var(--color1);
                    color: white;

                    &.clear {
                        background-color: #e56767;
                    }
                }
            }

            &.name {
                flex-basis: 100%;
            }
        }
    }

    span.checkbox {
        display: inline-flex;
        align-items: center;
        gap: 10px;
        margin-top: 20px;

        label {
            color: #707070;
        }

        input[type="checkbox"] {
            accent-color: var(--color1);
            width: 18px;
        }
    }
}