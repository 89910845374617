#Table {
    .cursor-pointer {
        position: relative;
      }
      
      th.cursor-pointer:before {
        position: absolute;
        display: block;
        opacity: .125;
        left: 2px;
        line-height: 9px;
        font-size: .8em;
        bottom: 50%;
        content: "▲";
        margin-left: 5px;
      }
      th.cursor-pointer {
        padding-right: 22px !important;
      }
      th.cursor-pointer:after {
        position: absolute;
        display: block;
        opacity: .125;
        left: 2px;
        line-height: 13px;
        font-size: .8em;
        top: 50%;
        content: "▼";
        margin-left: 5px;
      }
      
      th.cursor-pointer.active:after {
        opacity: 1;
        color: var(--primary);
      }
      th.cursor-pointer.inactive:before {
        opacity: 1;
        color: var(--primary);
      }
    section.table {
        table {
            width: 100%;

            thead {

                tr {
                    border: none;
                    background-color: #D9F5D880;

                    th {
                        padding: 15px 20px;
                        font-size: 16px;
                        color: #707070;
                        font-weight: 600;
                    }
                }
            }

            tbody {
                tr {
                    border: none;

                    td {
                        padding: 15px 10px;
                        font-size: 16px;
                        color: #5a5d5c;
                        font-weight: 600;
                       text-align: right;

                        img {
                            margin-left: 15px;
                            cursor: pointer;

                            &.eye {
                                width: 21px;
                            }

                            &.delete {
                                width: 16px;
                            }

                            &.edit {
                                width: 16px;
                            }
                        }

                        input[type='checkbox'] {
                            height: fit-content;
                            width: 15px;
                            aspect-ratio: 1 / 1;
                            accent-color: var(--color1);
                            width: 18px;
                        }
                    }

                    &:nth-child(even) {
                        background-color: #EEEEEE70;
                    }
                }
            }

            th:not(:last-child),
            td:not(:last-child) {
                border-left: 3px solid #0D7B581f;
            }

            
        }
    }
}

// tr.pending {
//     background: #f0ad4e;
//     color: #fff !important;
// }
tr.pending td {
    color: #f0ad4e !important;
}

// tr.approved {
//     background: #0da06e;
//     color: #fff !important;
// }
tr.approved td {
    color: #0da06e !important;
}

// tr.returned {
//     background: #60718a;
//     color: #fff !important;
// }
tr.returned td {
    color: #60718a !important;
}
// tr.rejected {
//     background: #e95030;
//     color: #fff !important;
// }
tr.rejected td {
    color: #e95030 !important;
}

tr.closed {
    color: #0a0f31 !important;
}