#SetPersonCount {
    line-height: .7;

    label {
        font-weight: 600;
    }

    span {
        position: relative;
        display: inline-flex;
        align-items: center;

        select {
            color: #707070;
            width: 220px;
            appearance: none;
        }

        img {
            position: absolute;
            left: 10px;
            width: 12px;
        }
    }

    .select__single-value {
        height: 20px;
        line-height: 20px;
    }
}