#Modal-platform {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: .5);
    right: 0;
    top: 0;
    z-index: 10000;

    &.display {
        display: block;
        animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        #Modal {
            opacity: 0;
            animation: scaleUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }

    &.display-none {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        #Modal {
            animation: scaleDown .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }
    }

    &.hide {
        display: none;
    }

    #Modal {
        min-height: 200px;
        min-width: 400px;
        width: fit-content;
        background-color: white;
        border-radius: 10px;
        position: relative;
        margin: 150px auto 0;
        padding: 10px;

        svg {
            position: absolute;
            left: 10px;
            top: 14px;        
            cursor: pointer;
        }
    }
}

@keyframes fadeIn {
    0% {
        background: rgba(0, 0, 0, .0);
    }

    100% {
        background: rgba(0, 0, 0, .7);
    }
}

@keyframes fadeOut {
    0% {
        background: rgba(0, 0, 0, .7);
    }

    100% {
        background: rgba(0, 0, 0, .0);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(.8) translateY(1000px);
        opacity: 0;
    }

    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }
}

@keyframes scaleDown {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 1;
    }

    100% {
        transform: scale(.8) translateY(1000px);
        opacity: 0;
    }
}