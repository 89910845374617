#Layout {
    aside {
        background-color: var(--color1);
        position: fixed;
        right: 0;
        height: 100vh;
        width: var(--navBarWidth);
        z-index: 200;
    }

    &>div {

        margin-right: var(--navBarWidth);

        &>header {
            height: var(--headerHeight);
            z-index: 100;
            position: sticky;
            top: 0;
            // display: flex;
            align-items: center;
            background-color: #fff;
        }

        main {
            background-color: #F5F5F7;
            overflow: hidden;
            min-height: 100vh;
        }
    }
}