#FormSearch {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &>span {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: calc((100% / 3) - 40px);
        position: relative;

        &:nth-of-type(4) {
            flex-basis: calc((100% / 3) * 2 - 30px);
        }

        &.button {
            display: inline-flex;
            align-items: flex-end;

            button {
                width: 150px;
                background-color: var(--color1);
                color: white;
            }
        }

        select,
        input {
            width: 100%;
            height: 45px;
            appearance: none;
        }

        img.icon {
            position: absolute;
            left: 10px;
            bottom: 10px;
            pointer-events: none;

            &.arrow {
                filter: brightness(0) saturate(100%) invert(76%) sepia(84%) saturate(408%) hue-rotate(87deg) brightness(88%) contrast(90%);
                bottom: 15px;
                width: 13px;
            }
        }
    }
}