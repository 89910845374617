#Layout_pdf {
    position: relative;

    div.document-platform {
        margin: 120px 50px 110px 80px;
        background-color: white;
        // box-shadow: 0px 0px 15px 2px #00000029;
        overflow: auto;

        header {
            display: flex;
            justify-content: space-between;
            margin: 30px 80px 0;
            padding-bottom: 20px;
            border-bottom: 2px solid #70707030;

            img.logo-2 {
                width: 170px;
            }
        }

        img.circles-loader {
            position: fixed;
            top: 421px;
            right: 970px;
            width: 100px;
            z-index: 10000;
        }

        section.document-details {
            h3 {
                text-align: center;
            }

            div.primary-details {
                display: flex;
                gap: 25px;
                background-color: #F1F1F1;
                padding: 30px 20px;
            }

            div.secondary-details {}
        }

        .buttons {
            position: absolute;
            bottom: -80px;
            left: 80px;
            display: inline-flex;
            gap: 15px;

            button {
                background-color: var(--color1);
                color: white;
                width: 150px;

                &.cancel {
                    background-color: #434343;
                }

            }
        }
    }
}

.canvasing {
    // width: 100%;
}